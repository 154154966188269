import { create } from "zustand";

interface SmartrrModalState<PayloadType> {
  isModalOpen: boolean;
  modalPayload: PayloadType | null;
  openModal: (modalPayload: PayloadType) => void;
  closeModal: () => void;
}

export const createSmartrrModal = function <PayloadType>() {
  return create<SmartrrModalState<PayloadType>>()(set => ({
    isModalOpen: false,
    modalPayload: null,
    openModal: (modalPayload: PayloadType) => set(() => ({ isModalOpen: true, modalPayload })),
    closeModal: () => set(() => ({ isModalOpen: false, modalPayload: null })),
  }));
};
